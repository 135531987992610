<template>
  <v-container fluid>
    <v-row class="text-h2 mt-16" justify="center">
      <div>
        Недостаточно
        <span class="orange--text"> прав доступа</span>
      </div>
    </v-row>
    <v-row class="text-h1 mt-16" justify="center"> </v-row>
  </v-container>
</template>

<script>
export default {
  name: "NoRights",
};
</script>

<style>
</style>